import React from 'react'
import SocketClient from '@mountkelvin/websocket-client'
import BodyClassName from 'react-body-classname'

import './App.css';
import imageDamianJasnaLogo from './images/damianjasna.png'

function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL || "https://kelvin-nelonen-server.herokuapp.com"

const DAMIAN_SITE_ID = 'af737d55-804d-431f-9a6a-4a27c3995daf'
const DAMIAN_FORCE_SPACE_ID = '47905cf2-b3a7-4c4f-b3f3-3e03b438b787'

const qs = parseQuery(window.location.search)

const authToken = qs.token || qs.t
const targetSiteId = qs.siteId || qs.st
const targetSpaceId = qs.spaceId || qs.s

const initializeSocket = (url) => {
  const authenticate = async (authToken) => {
    const res = await ws.request('auth', { token: authToken })
    if(res.error) {
      console.error(`Authentication failed: ${JSON.stringify(res.error)}`)
    } else {
      console.log("Authentication success")
    }
  }

  const ws = new SocketClient(url)
  ws.on('connect', () => console.log("Websocket connected"))
  ws.on('disconnect', () => console.log("Websocket disconnected"))

  return { ws, authenticate }
}

const matchesScene = (scene, spaceLightObjects, siteStates) => {
  if(!scene) return false
  return scene.applyList.every(({ state, objectId }) => {
    if(!spaceLightObjects.find(({ id }) => id === objectId)) {
      return false
    }

    const siteState = siteStates[objectId]
    if(!siteState) return false

    return (!state.on && !siteState.on) || Math.abs(state.bri - siteState.bri) <= 1
  })
}

class App extends React.Component {
  state  = { siteConfiguration: null, activeBri: null, siteStates: {} }
  ws = null

  async componentDidMount() { 
    const url = 'ws' + SERVER_URL.replace(/^http(s?)/, '$1') + '/v4'
    const { ws, authenticate } = initializeSocket(url) 
    this.ws = ws

    this.ws.method('site.index', this.onSiteIndex)
    this.ws.method('site.state', this.onSiteState)
    this.ws.method('site.configuration', this.onSiteConfiguration)

    await authenticate(authToken)
  }

  get siteId() {
    const { siteConfiguration } = this.state
    return siteConfiguration ? siteConfiguration.id : null
  }

  get space() {
    const { siteConfiguration } = this.state
    if (siteConfiguration) {
      if (this.siteId === DAMIAN_SITE_ID) {
        return siteConfiguration.spaces.find(sp => sp.id === DAMIAN_FORCE_SPACE_ID)
      } else if (targetSpaceId) {
        return siteConfiguration.spaces.find(sp => sp.id === targetSpaceId)
      }
      return siteConfiguration.spaces[0]
    }
    return null
  }

  get spaceId() {
    return this.space ? this.space.id : null
  }

  calculateActiveBriBasedOnSiteState() {
    const { siteConfiguration, siteStates } = this.state

    const spaceScenes = siteConfiguration.scenes.filter(s => s.spaceId === this.spaceId)
    const spaceRooms = siteConfiguration.rooms.filter(r => r.spaceId === this.spaceId)
    const spaceObjects = siteConfiguration.objects.filter(o => spaceRooms.some(r => r.id === o.roomId))
    const spaceLightObjects = spaceObjects.filter(o => o.type === 'light')

    const spaceSiteStates = spaceLightObjects.map(o => siteStates[o.id]).filter(Boolean)

    if(spaceSiteStates.length === 0) return null
    else if(matchesScene(spaceScenes.find(({ name }) => name === 'Daytime'), spaceLightObjects, siteStates)) return 255
    else if(matchesScene(spaceScenes.find(({ name }) => name === 'Evening'), spaceLightObjects, siteStates)) return 178
    else if(matchesScene(spaceScenes.find(({ name }) => name === 'Night'), spaceLightObjects, siteStates)) return 76
    else if(spaceSiteStates.every(s => s.bri === 255)) return 255
    else if(spaceSiteStates.every(s => s.bri === 178)) return 178
    else if(spaceSiteStates.every(s => s.bri === 76)) return 76
    else if(spaceSiteStates.every(s => s.bri === 0)) return 0

    return null
  }

  onSiteIndex = async ({ siteList }) => {
    const site = siteList.find(({ id }) => id === targetSiteId) || siteList[0]
    if(site) {
      await this.ws.request('site.subscribe', { siteId: site.id })
      console.log(`Subscription to site ${site.id} success`)
    } else  {
      console.error("No sites found in site.index response")
    }
  }

  onSiteState = ({ siteStateList }) => {
    if(siteStateList.length > 0) {
      this.setState(
        ({ siteStates }) => 
          ({ 
            siteStates: {
              ...siteStates, 
              ...Object.fromEntries(siteStateList.map(s => [s.id, { ...siteStates[s.id],  ...s.state }])) 
            }
          }),
          () => this.state.siteConfiguration && this.setState({ activeBri: this.calculateActiveBriBasedOnSiteState() })
        )
    }
  }

  onSiteConfiguration = ({ siteConfiguration }) => {
    this.setState(
      { siteConfiguration }, 
      () =>  this.setState({ activeBri: this.calculateActiveBriBasedOnSiteState() })
    )
  }

  setScene = async (sceneName, fallbackBri) => {
    const { siteConfiguration } = this.state
    const scene = siteConfiguration.scenes.find(({ spaceId, name }) => name === sceneName && spaceId === this.spaceId)
    if(scene) {
      await this.ws.request('apply', { siteId: this.siteId, targetSceneId: scene.id })
    } else {
      await this.ws.request('apply', { siteId: this.siteId, targetSpaceId: this.spaceId, state:  { bri: fallbackBri, on: fallbackBri !== 0 }})
    }

    this.setState({ activeBri: fallbackBri })
  }

  render() {
    const { siteConfiguration, activeBri } = this.state
    if(!siteConfiguration || !this.space) return null
    document.title = this.space.name

    const isDamianJasna = this.siteId === 'af737d55-804d-431f-9a6a-4a27c3995daf'

    return (
      <BodyClassName className={'BodyBackground' + (isDamianJasna ? ' damian' : '')}>
        <div className="App">
          <div className="App__background" />
          <div className="App__name">
            <span className="App__hotel">
              {siteConfiguration.name}
              {isDamianJasna && <span className="App__hotel-subtitle">HOTEL RESORT & RESIDENCES</span>}
            </span>
            
            <span className="App__space">{this.space.name}</span>
            {isDamianJasna &&
              <div className="App__logo">
                <img src={imageDamianJasnaLogo} alt="" />
              </div>
            }
          </div>
          <div className='App__controls'>
            <span className={'App__control ' + (activeBri === 255 && 'App__control--active')} onClick={() => this.setScene('Daytime', 255)}>
              Daytime
            </span>
            <span className={'App__control ' + (activeBri === 178 && 'App__control--active')} onClick={() => this.setScene('Evening', 178)}>
              Evening
            </span>
            <span className={'App__control ' + (activeBri === 76 && 'App__control--active')} onClick={() => this.setScene('Night', 76)}>
              Night
            </span>
            <span className={'App__control ' + (activeBri === 0 && 'App__control--active')} onClick={() => this.setScene(null, 0)}>
              Off
            </span>
          </div>
          {isDamianJasna &&
            <div className='App__controls'>
              <span className='App__control'>Open curtains</span>
              <span className='App__control'>Close curtains</span>
            </div>
          }
        </div>
      </BodyClassName>
    )
  }
}

export default App;
